import { gsap } from 'gsap';

const windowWidth = document.documentElement.clientWidth;
const windowHeight = document.documentElement.clientHeight;
const tubes = document.querySelectorAll('body > .tube');
const headerTubes = document.querySelectorAll('.header__tubes > .tube');
const sections = document.querySelectorAll('.section');

export const getSectionTimeline = (section) => {
  return gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top 75%',
      toggleActions: 'play none none reverse',
      invalidateOnRefresh: true
    }
  });
};

export const setActiveSection = (section, tl, position) => {
  const currentIndex = [...sections].indexOf(section);
  const activeSections = [...sections].slice(currentIndex);

  tl.set('.section', { zIndex: 0 }, position);
  tl.set(activeSections, { zIndex: 1 }, position);
};

export const showTube = (tubeIndex, tl, position) => {
  const tube = tubes[tubeIndex];
  const headerTube = headerTubes[tubeIndex];

  if (!tube) {
    return;
  }

  const tubeRect = tube.getBoundingClientRect();

  tl.to(
      tube,
      {
        scaleX: (windowWidth / tubeRect.width) * 1.3,
        scaleY: (windowHeight / tubeRect.height) * 1.3,
        duration: 1,
        ease: 'power3.inOut'
      },
      position
  );

  tl.to(
      headerTubes,
      {
        opacity: 0,
        duration: 1,
        ease: 'power3.inOut'
      },
      '<'
  );

  tl.to(
      headerTube,
      {
        scaleX: (windowWidth / tubeRect.width) * 1.3,
        scaleY: (windowHeight / tubeRect.height) * 1.3,
        opacity: 0.97,
        duration: 1,
        ease: 'power3.inOut'
      },
      '<'
  );
};

export const showSection = (section, tl, position) => {
  const sectionLink = document.querySelector(`.header__nav [href*="${section.id}"]`);

  tl.fromTo(
      section,
      {
        opacity: 0
      },
      {
        opacity: 1,
        duration: 0.5,
        ease: 'power1.inOut'
      },
      position
  );

  tl.set(
      '.header__nav a',
      {
        className: ''
      },
      '<'
  );

  if (!sectionLink) {
    return;
  }

  tl.set(
      sectionLink,
      {
        className: 'active'
      },
      '<'
  );
};

export const setParallax = (el) => {
  const offset = el.clientHeight / 3;

  return gsap.fromTo(
      el,
      {
        y: `${-offset}px`
      },
      {
        scrollTrigger: {
          trigger: el,
          start: `${window.innerHeight - offset}px bottom`,
          end: () => `bottom ${-(window.innerHeight + offset)}px`,
          scrub: 0,
          invalidateOnRefresh: true
        },
        y: `${offset}px`,
        duration: 1,
        ease: 'none'
      }
  );
};
